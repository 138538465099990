<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="4" lg="4" sm="6">
                        <b-form-group label="Username" label-for="agency" class="mb-2">
                            <b-form-input id="username" v-model="search.username" class="form-control"
                                placeholder="Please Enter Agency" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="4" lg="4" sm="6">
                        <b-form-group label="Email" label-for="email" class="mb-2">
                            <b-form-input id="code" v-model="search.email" class="form-control" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="4" lg="4" sm="6">
                        <b-form-group label="Phone" label-for="phone" class="mb-2">
                            <b-form-input id="code" v-model="search.phone" class="form-control" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4" lg="4" sm="6">
                        <b-form-group label="Status" label-for="status" class="mb-2">
                            <v-select v-model="search.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :clearable="false" :options="statusOptions" :reduce="option => option.value" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="4" lg="4" sm="6" class="mb-2 ipad">
                        <b-form-group :label="$t('From date')" label-for="fromDate">
                            <flat-pickr id="fromDate" v-model="search.fromDate" class="form-control"
                                placeholder="YYYY-MM-DD" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4" lg="4" sm="6" class="mb-2 ipad">
                        <b-form-group :label="$t('To date')" label-for="toDate">
                            <flat-pickr id="toDate" v-model="search.toDate" class="form-control" placeholder="YYYY-MM-DD" />
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row class="d-flex align-items-end justify-content-end">
                    <b-col cols="12" md="4" class="d-flex align-items-end justify-content-end">
                        <b-button variant="outline-primary" @click="resetFilter()">
                            Reset
                        </b-button>
                        <b-button variant="primary" class="ml-2" @click="searchFilter()">
                            Search
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body>
            <b-table ref="refSubAgencyListTable" class="position-relative table-white-space" :items="listSubAgency"
                responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + data.index + 1 }}
                </template>
                <template #cell(username)="data">
                    <!-- @click="handleUpdateAgency(data.item)" -->
                    <b-link class="cursor-pointer text-primary" v-if="$can('read', 'agencydetail')" :to="{
                        name: 'agency-detail',
                        params: { id: data.item.id },
                    }">
                        {{ data.item.username }}
                    </b-link>
                </template>

                <template #cell(email)="data">
                    {{ data.item.email }}
                </template>
                <template #cell(code)="data">
                    {{ data.item.code }}
                </template>
                <template #cell(user_count)="data">
                    {{ data.item.users_count }}
                </template>

                <template #cell(phone)="data">
                    {{ data.item.phone }}
                </template>

                <template #cell(password)="data">
                    {{ data.item.password }}
                </template>

                <template #cell(discount)="data">
                    <span v-if="data.item.commission_type === 1">
                        Flexible
                    </span>
                    <span v-else>
                        Fixed ({{ data.item.discount }}%)
                    </span>
                </template>

                <template #cell(status)="data">
                    <div :class="`text-capitalize align-items-center d-flex`">
                        <span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{
                            resolveStatus(data.item.status).label }}
                    </div>
                </template>

                <template #cell(created_at)="data">
                    {{
                        data.item.created_at
                        ? formatDateTime(data.item.created_at).replace(".000000Z", "")
                        : ""
                    }}
                </template>

                <template #cell(updated_at)="data">
                    {{
                        data.item.updated_at
                        ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
                        : ""
                    }}
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
                        <b-pagination v-model="currentPage" :total-rows="totalSubAgency" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg, BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";
import i18n from "@/libs/i18n";
import VueExportExcel from "@/views/extensions/export/VueExportExcel";

export default {
    components: {
        flatPickr,
        AddSportsTeamModal,
        UpdateSportTeamModal,
        VueExportExcel,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BImg,
        BSpinner,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        userData: {
            type: Object,
        },
    },
    data() {
        return {
            sportTeamDetail: null,
            exportFiletitle: `AgencyCommission-${new Date()
                .toJSON()
                .replace(":", "-")
                .substring(0, 16)}`,
            exportFileSheetName: "AgencyCommission",
        };
    },
    methods: {},
    setup(props) {
        if (!store.hasModule("marketing"))
            store.registerModule("marketing", marketingStoreModule);
        onUnmounted(() => {
            if (store.hasModule("marketing")) store.unregisterModule("marketing");
        });

        const search = ref({
            username: "",
            email: "",
            phone: "",
            status: "",
            fromDate: "",
            toDate: "",
        });
        const listSubAgency = ref([]);
        const perPage = ref(25);
        const totalSubAgency = ref(0);
        const currentPage = ref(1);
        const perPageOptions = [10, 25, 50, 100];
        const sortBy = ref("id");
        const isSortDirDesc = ref(true);

        const refSubAgencyListTable = ref(null);
        const tableColumns = [
            { key: "index", label: "#" },
            { key: "username", label: i18n.t("Agency Name") },
            { key: "code", label: i18n.t("Agency Code") },
            { key: "email", label: i18n.t("Email") },
            { key: "phone", label: i18n.t("Phone") },
            { key: "status", label: i18n.t("Status") },
            { key: "discount", label: i18n.t("Discount_agency") },
            { key: "user_count", label: i18n.t("User Count") },
            { key: "debt_previous", label: i18n.t("Dư nợ") },
            { key: "created_by", label: i18n.t("Created by") },
            { key: "created_at", label: i18n.t("Created at") },
            { key: "updated_at", label: i18n.t("Updated at") },
            { key: "updated_by", label: i18n.t("Updated by") },
        ];

        watch(
            [currentPage],
            () => {
                fetchSubAgency();
            }
        );
        const fetchSubAgency = (ctx, callback) => {
            store
                .dispatch("marketing/fetchSubAgency", {
                    page: currentPage.value,
                    parentId: props.userData.id,
                    username: search.value.username,
                    email: search.value.email,
                    phone: search.value.phone,
                    status: search.value.status,
                    fromDate: search.value.fromDate,
                    toDate: search.value.toDate,
                })
                .then((response) => {
                    if (response.data.code == "00") {
                        // callback(response.data.data);
                        listSubAgency.value = response.data.data
                        totalSubAgency.value = response.data.count;
                        perPage.value =
                            currentPage.value == 1 ? response.data.data.length : 25;
                    }
                });
        };

        store
            .dispatch("marketing/fetchSubAgency", {
                page: currentPage.value,
                username: search.value.username,
                parentId: props.userData.id,
                email: search.value.email,
                phone: search.value.phone,
                status: search.value.status,
                fromDate: search.value.fromDate,
                toDate: search.value.toDate,
            })
            .then((response) => {
                if (response.data.code == "00") {
                    // callback(response.data.data);
                    listSubAgency.value = response.data.data
                    totalSubAgency.value = response.data.count;
                    perPage.value =
                        currentPage.value == 1 ? response.data.data.length : 25;
                }
            });

        const dataMeta = computed(() => {
            const localItemsCount = refSubAgencyListTable.value
                ? refSubAgencyListTable.value.localItems.length
                : 0;
            return {
                from:
                    perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalSubAgency.value,
            };
        });

        const resetFilter = () => {
            // search.value.name = "";
            search.value.username = "";
            search.value.email = "";
            search.value.phone = "";
            search.value.status = "";
            search.value.fromDate = "";
            search.value.toDate = "";
            fetchSubAgency();
        };

        const searchFilter = () => {
            fetchSubAgency();
        };

        const statusOptions = [
            { label: i18n.t('Active'), value: 1 },
            { label: i18n.t('Inactive'), value: 2 },
            { label: i18n.t('Pending'), value: 3 },
        ];

        const resolveStatus = (status) => {
            if (status === 1) return { label: i18n.t("Active"), variant: "success" };
            if (status === 2) return { label: i18n.t("Inactive"), variant: "danger" };
            if (status === 3) return { label: i18n.t("Pending"), variant: "warning" };
        };

        return {
            tableColumns,
            statusOptions,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            sortBy,
            isSortDirDesc,
            refSubAgencyListTable,
            fetchSubAgency,
            search,
            resetFilter,
            listSubAgency,
            numberFormat,
            formatDateTime,
            searchFilter,
            baseUrl,
            totalSubAgency,
            resolveStatus
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  