<template>
  <div>
    <b-card v-if="userData.userData">
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar size="104px" rounded />
          </div>
        </b-col>
        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 w-100">
            <tr>
              <th class="pb-1">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Login Name") }}</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="SendIcon" class="mr-75" />
                <span class="font-weight-bold">Email</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.email }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Phone") }}</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.phone }}
              </td>

            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Status") }}</span>
              </th>
              <td class="pb-1">
                <div :class="` text-${resolveStatus(userData.userData.status).variant
                  }`">
                  {{ resolveStatus(userData.userData.status).label }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="AirplayIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Mã đại lý cha") }}</span>
              </th>
              <td class="pb-1">
                <div>
                  {{ userData.userData.aff_refferal }}
                </div>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="FigmaIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Dự nợ tháng trước") }}</span>
              </th>
              <td class="pb-1">
                <div>
                  {{ userData.userData.debt_previous ? numberFormat(userData.userData.debt_previous / 1000) : '' }}
                </div>
              </td>
            </tr>
          </table>
        </b-col>
        <b-col cols="12" xl="6">
          <table class="mt-2 w-100">
            <tr>
              <th class="pb-1">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Updated at") }}</span>
              </th>
              <td class="pb-1">
                {{
                  formatDateTime(userData.userData.updated_at).replace(
                    ".000000Z",
                    ""
                  )
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="GlobeIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Updated by") }}</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.updated_by }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="ShieldIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Created By") }}</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.created_by }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t("Registration date")
                }}</span>
              </th>
              <td class="pb-1">
                {{
                  formatDateTime(userData.userData.created_at).replace(
                    ".000000Z",
                    ""
                  )
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="CpuIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Loại hoa hồng") }}</span>
              </th>
              <td class="pb-1">
                <span v-if="userData.userData.commission_type === 1">
                  Flexible
                </span>
                <span v-else>
                  Fixed ({{ userData.userData.discount }}%)
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="SlidersIcon" class="mr-75" />
                <span class="font-weight-bold">Mạng xã hội</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.social_network }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="ToolIcon" class="mr-75" />
                <span class="font-weight-bold">Thông tin mạng xã hội</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.social_network_contact }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <user-agency-edit :userData="dataChild" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import { ref, onUnmounted, onUpdated, onMounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  formatRemoveTime,
  formatDateTime,
  hiddenEmail,
  resolveCurrency,
} from "@core/utils/filter";
// import UsersEdit from "@/views/apps/user/users-edit/UsersEdit.vue";
// import UserDetailChangeStatusModal from "@/views/apps/user/users-detail/UserDetailChangeStatusModal.vue";
// import UserViewOutstandingBalancesModal from "@/views/apps/user/users-view/UserViewOutstandingBalancesModal.vue";
import store from "@/store";
import router from "@/router";
// import cashLogStoreModule from "@/views/apps/user/users-detail/cash-log/cashLogStoreModule";
import UserAgencyEdit from "@/views/marketing/agency-edit/UserAgencyEdit.vue";
// import DetailReportAgency from "@/views/marketing/agency-detail/DetailReportAgency.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useAgencyList from "@/views/marketing/useAgencyList";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BLink,
    BOverlay,
    UserAgencyEdit,
    // DetailReportAgency,
    // UsersEdit,
    // UserDetailChangeStatusModal,
    // UserViewOutstandingBalancesModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {};
  },
  setup(props) {
    const dataChild = ref(props.userData.userData);
    // const detailId = ref(props.userData.userData.id);
    // const selectedComponent = ref({
    //   component: UserListAgency,
    //   id: detailId.value,
    // });
    // const activeIndex = ref(0);
    // const selectComponent = (index) => {
    //   selectedComponent.value = {
    //     component: componentDynamic[index].component,
    //     id: componentDynamic[index].id,
    //   };
    //   activeIndex.value = index;
    // };
    // const componentDynamic = [
    //   { name: "UserListAgency", component: UserListAgency, id: detailId.value },
    //   {
    //     name: "DetailReportAgency",
    //     component: DetailReportAgency,
    //     id: detailId.value,
    //   },
    // ];

    const {resolveStatus} = useAgencyList();

    return {
      dataChild,
      numberFormat,
      resolveStatus,
      formatDateTime,
    };
  },
};
</script>

<style scoped lang="css">
li.active {
  background-color: #3cc2bb;
}
</style>
