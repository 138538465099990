<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData" content-class="mt-2" pills>
      <b-tab  v-if="$can('read', 'agencyuserlist')" active>
        <template #title>
          <span>{{ $t("Danh sách user") }}</span>
        </template>
        <UserListAgency :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Deposit -->
      <b-tab  v-if="$can('read', 'agencyrevenue')" lazy>
        <template #title>
          <span>{{ $t("Doanh Thu Cược") }}</span>
        </template>
        <DetailReportAgency :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Deposit -->
      <b-tab  v-if="$can('read', 'agencypaymenthistory')" lazy>
        <template #title>
          <span>{{ $t("Lịch sử thanh toán") }}</span>
        </template>
        <DetailAgencyHistory :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Win/Loss -->
      <b-tab  v-if="$can('read', 'agencyaccountingwinloss')" lazy>
        <template #title>
          <span>{{ $t("Win/Loss By Product") }}</span>
        </template>
        <DetailAgencyWinLoss :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Win/Loss -->
      <b-tab  v-if="$can('read', 'agencyaccountingwinlossbyuser')" lazy>
        <template #title>
          <span>{{ $t("Win/Loss By User") }}</span>
        </template>
        <DetailAgencyWinLossByUser :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Commission -->
      <b-tab  v-if="$can('view', 'agencycommissions')" lazy>
        <template #title>
          <span>{{ $t("Commission") }}</span>
        </template>
        <DetailAgencyCommission :user-data="userData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Sub Agency -->
      <b-tab  v-if="$can('read', 'subagencylist')" lazy>
        <template #title>
          <span>{{ $t("Sub Agency") }}</span>
        </template>
        <SubAgencyList :user-data="userData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import UserListAgency from "@/views/marketing/agency-detail/UserListAgency.vue";
import DetailReportAgency from "@/views/marketing/agency-detail/DetailReportAgency.vue";
import DetailAgencyHistory from "@/views/marketing/agency-detail/DetailAgencyHistory.vue";
import DetailAgencyWinLoss from "@/views/marketing/agency-detail/DetailAgencyWinLoss";
import DetailAgencyCommission from "@/views/marketing/agency-detail/DetailAgencyCommission";
import DetailAgencyWinLossByUser from "@/views/marketing/agency-detail/DetailAgencyWinLossByUser";
import SubAgencyList from "@/views/marketing/agency-detail/SubAgencyList.vue";

export default {
  components: {
    DetailAgencyWinLossByUser,
    DetailAgencyCommission,
    DetailAgencyWinLoss,
    SubAgencyList,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserListAgency,
    DetailReportAgency,
    DetailAgencyHistory,
  },
  props: {
    userData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup(props) {
    //   const userData = ref(null);
    //   const loginLogs = ref(null);
    //   const roleOptions = ref([]);
    //   const levelLists = ref(null);

    //   const USER_APP_STORE_MODULE_NAME = "app-user";
    //   const LOCAL_SETTING_ROLE_STORE_MODULE_NAME = "local-setting-role";

    //   // Register module
    //   if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
    //     store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    //   if (!store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME))
    //     store.registerModule(
    //       LOCAL_SETTING_ROLE_STORE_MODULE_NAME,
    //       roleStoreModule
    //     );

    //   // UnRegister on leave
    //   onUnmounted(() => {
    //     // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    //     if (store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME))
    //       store.unregisterModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME);
    //   });

    //   store
    //     .dispatch("app-user/fetchUserDetail", router.currentRoute.params.id)
    //     .then((response) => {
    //       userData.value = response.data.data.user;
    //       loginLogs.value = response.data.data.loginLogs;
    //     });

    //   store.dispatch("local-setting-role/fetchRoles").then((response) => {
    //     roleOptions.value = response.data;
    //   });

    //   store.dispatch("app-user/fetchLevel").then((response) => {
    //     levelLists.value = response.data.data;
    //   });

    return {
      // userData,
      // roleOptions,
      // levelLists,
      // loginLogs,
    };
  },
};
</script>

<style></style>
