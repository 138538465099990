<template>
  <div v-if="loading" class="d-flex justify-content-center mb-3">
    <b-spinner variant="primary" label="Spinning"></b-spinner>
  </div>
  <div v-else>
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-agency-info-card :user-data="{ userData }" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink, BSpinner } from "bootstrap-vue";
import store from "@/store";
import router from "@/router";
import marketingStoreModule from "../marketingStoreModule";
import useAgencyList from "@/views/marketing/useAgencyList";
import UserViewAgencyInfoCard from "./UserViewAgencyInfoCard.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BSpinner,

    // Local Components
    UserViewAgencyInfoCard,
  },
  setup() {
    const userData = ref(null);

    const balances = ref("");

    const balanceType = ref(null);

    const provinceLists = ref(null);

    const USER_APP_STORE_MODULE_NAME = "marketing";
    // const MARKETING_MODULE_NAME = "marketing";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, marketingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const loading = ref(false);
    const toast = useToast();

    const fetchAgency = (ctx, callback) => {
      loading.value = true;
      store
        .dispatch("marketing/fetchAgencyDetail", router.currentRoute.params.id)
        .then((response) => {
          if (response.data.code == "00") {
            userData.value = response.data.data;
            userData.value.social_network = listSocialNetwork.value ? listSocialNetwork.value.filter((x) => x.id === userData.value.social_network_id)[0]?.name : userData.value.social_network_id
            loading.value = false;
          } else {
            loading.value = false;
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } 
        })
        .catch((error) => {
          loading.value = false;
          toast({
              component: ToastificationContent,
              props: {
                title: 'Vui lòng f5 để thử lại hoặc liên hệ với bộ phận IT',
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
        });
    }
    fetchAgency();

    const {
      listSocialNetwork
    } = useAgencyList();

    return {
      balances,
      balanceType,
      userData,
      provinceLists,
      loading
    };
  },
};
</script>

<style></style>
