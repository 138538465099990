<template>
  <div>
    <b-modal
      id="modal-add-agency-history"
      cancel-variant="outline-secondary"
      :ok-title="$t('Add')"
      :cancel-title="$t('Close')"
      ok-variant="success"
      centered
      no-enforce-focus
      modal-class="modal-success"
      :title="$t('Add Agency History')"
      @ok="confirmAdd"
      @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="addSportTeamModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="amount">
            <label class="mb-1"
              ><span class="text-danger">*</span>{{ $t("Amount ") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="amount"
              rules="required"
            >
              <b-form-input
                id="amount"
                v-model="post.amount"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="note">
            <label class="mb-1"
              ><span class="text-danger">*</span>{{ $t("Note ") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="note"
              rules="required"
            >
              <b-form-textarea
                id="internal-payment-note"
                v-model="post.note"
                placeholder="Note"
                rows="4"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="month">
            <label class="mb-1"
              ><span class="text-danger">*</span>{{ $t("Month") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="month"
              rules="required"
            >
              <v-select
                v-model="post.month"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dataMonth"
                :reduce="(val) => val.value"
                placeholder="Select month"
                input-id="month"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="year">
            <label class="mb-1"
              ><span class="text-danger">*</span>{{ $t("Year") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="year"
              rules="required"
            >
              <v-select
                v-model="post.year"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :reduce="(val) => val.value"
                placeholder="Select year"
                input-id="year"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="banners">
            <label for=""
              ><span class="text-danger">*</span> {{ $t("Bills") }}
              <span class="text-secondary">(Không > 10Mb)</span></label
            >
            <validation-provider
              #default="{ errors }"
              name="banners"
              rules="required|size:10240"
            >
              <b-form-file
                v-model="post.banners"
                multiple
                input-id="banners"
                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            v-show="post.banners.length !== 0"
            variant="outline-danger"
            class="btn-sm btn-icon"
            @click="post.banners = []"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import Editor from "@tinymce/tinymce-vue";
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Editor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    sportTypeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      API_KEY,
      post: {
        agency_id: this.sportTypeId,
        amount: "",
        note: "",
        month: "",
        year: "",
        typeId: "",
        banners: []
      },
    };
  },
  methods: {
    validationForm() {
      this.$refs.addSportTeamModal.validate().then((success) => {
        if (success) {
          this.addPost(this.post);
        }
      });
    },
    async addPost(sportTeamData) {
      await this.$store
        .dispatch("marketing/addAgencyHistory", sportTeamData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-add-agency-history");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.post = {
        agency_id: this.sportTypeId,
        amount: "",
        note: "",
        month: "",
        year: "",
        typeId: "",
        logo: null,
      };
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const dataMonth = [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
      { label: 7, value: 7 },
      { label: 8, value: 8 },
      { label: 9, value: 9 },
      { label: 10, value: 10 },
      { label: 11, value: 11 },
      { label: 12, value: 12 },
    ];
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 50 }, (_, index) => {
      const year = currentYear - index;
      return { label: year.toString(), value: year };
    });
    return { dataMonth, years };
  },
};
</script>
