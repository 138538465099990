<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Ref" label-for="name" class="mb-0">
              <b-form-input
                id="name"
                v-model="search.name"
                class="form-control"
                placeholder="Please Enter Name"
              />
            </b-form-group>
          </b-col>
          <!-- To Date Filter -->
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                id="toDate"
                v-model="search.todate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <!-- from Date Filter -->
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                id="fromDate"
                v-model="search.fromdate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="12" class="d-flex align-items-end justify-content-end">
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" v-if="$can('create', 'agencypayment')">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showAddAgencyHistoryModal()">
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> New
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refSportTeamListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :items="sportTeamLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(ref)="data">
          <b-link
            class="cursor-pointer text-primary"
            @click="showEditSportTeamModal(data.item)"
          >
            {{ data.item.ref }}
          </b-link>
        </template>
        <template #cell(note)="data">
          {{ data.item.note }}
        </template>
        <template #cell(amount)="data">
          {{ numberFormat(data.item.amount / 1000) }}
        </template>
        <template #cell(month)="data">
          {{ data.item.month }}
        </template>
        <template #cell(year)="data"> {{ data.item.year }} </template>

        <template #cell(bills)="data">
          <div style="display: flex; justify-content: flex-start; gap: 10px">
            <div v-for="img in data.item.bills" :key="img.id">
              <b-link
                :href="baseUrl + img.url"
                target="_blank"
                class="cursor-pointer"
              >
                <b-img
                  :src="baseUrl + img.url"
                  fluid
                  class="mr-2 rounded"
                  style="width: 50px; height: 50px"
                />
              </b-link>
              <!-- <b-link v-else></b-link> -->
            </div>
          </div>
        </template>

        <template #cell(created_by)="data">
          {{ data.item.created_by }}
        </template>
        <!-- <template #cell(updated_by)="data">
          {{ data.item.updated_by }}
        </template> -->
        <template #cell(created_at)="data">
          <div v-if="data.item.created_at !== null">
            {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
          </div>
          <div v-else></div>
        </template>

        <template #cell(updated_at)="data">
          <div v-if="data.item.updated_at !== null">
            {{ formatDateTime(data.item.updated_at).replace(".000000Z", "") }}
          </div>
          <div v-else></div>
        </template>

        <!-- <template #cell(action)="data">
          <b-link
            v-if="$can('update', 'sportteam')"
            class="font-weight-bold text-nowrap"
            @click="showEditSportTeamModal(data.item)"
          >
            Edit
          </b-link>
        </template> -->
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSportTeam"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal: Add sports Group -->
    <add-agency-history-modal
      :sport-type-id.sync="sportTypeId"
      @refetch-data="refetchData"
    />

    <!-- Modal: Edit sports Group -->
    <update-agency-history-modal
      :sport-type-id.sync="sportTypeId"
      :sport-team-detail.sync="sportTeamDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
// import { formatDateTime } from "@core/utils/filter";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import AddAgencyHistoryModal from "@/views/marketing/agency-detail/AddAgencyHistoryModal.vue";
import UpdateAgencyHistoryModal from "@/views/marketing/agency-detail/UpdateAgencyHistoryModal.vue";
import flatPickr from "vue-flatpickr-component";
// import baseUrl from "@/libs/base-url";

export default {
  components: {
    // AddAgencyHistoryModal,
    // EditsportTeamModal,
    AddAgencyHistoryModal,
    UpdateAgencyHistoryModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {
    showAddAgencyHistoryModal() {
      this.$bvModal.show("modal-add-agency-history");
    },
    showEditSportTeamModal(data) {
      this.sportTeamDetail = data;
      this.$bvModal.show("modal-edit-agency-history");
    },
  },
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    // onUnmounted(() => {
    //   if (store.hasModule("marketing")) store.unregisterModule("marketing");
    // });

    const toast = useToast();

    const search = ref({
      name: "",
      todate: "",
      fromdate: "",
      sport_type_id: "",
    });
    const sportTypeId = props.userData.id;
    const sportTypeIdFilter = ref(null);
    const nameFilter = ref(null);
    const toDateFilter = ref(null);
    const fromDateFilter = ref(null);

    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const refSportTeamListTable = ref(null);

    const tableColumns = [
      { key: "index", label: "#" },
      "ref",
      "bills",

      "note",
      "amount",
      "month",
      "year",
      "created_by",
      "created_at",
      "updated_at",
      //   "action",
    ];

    const refetchData = () => {
      refSportTeamListTable.value.refresh();
    };

    watch(
      [
        currentPage,
        nameFilter,
        toDateFilter,
        fromDateFilter,
        sportTypeIdFilter,
      ],
      () => {
        refetchData();
      }
    );
    const sportTeamLists = (ctx, callback) => {
      store
        .dispatch("marketing/fetchAgencyPaymentHistory", {
          agency_id: props.userData?.id,
          ref: nameFilter.value,
          fromDate: fromDateFilter.value,
          toDate: toDateFilter.value,
          //   sport_type_id: sportTypeIdFilter.value,
          page: currentPage.value,
        })
        .then((response) => {
          //   const { sportTeams, total, pageSize } = response;
          callback(response.paymentHistory);
          totalSportTeam.value = response.total;
          perPage.value = response.pageSize;
        });
    };
    //   store.dispatch("sports/fetchSportType",{}).then((response) => {
    //     sportTypeId.value = response.sportType;
    //   });

    const dataMeta = computed(() => {
      const localItemsCount = refSportTeamListTable.value
        ? refSportTeamListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportTeam.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "inActive", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };

    const resetFilter = () => {
      search.value.name = "";
      search.value.sport_type_id = "";
      search.value.todate = "";
      search.value.fromdate = "";
      nameFilter.value = null;
      toDateFilter.value = null;
      fromDateFilter.value = null;
      sportTypeIdFilter.value = null;
    };

    const searchFilter = () => {
      nameFilter.value = search.value.name;
      toDateFilter.value = search.value.todate;
      fromDateFilter.value = search.value.fromdate;
      sportTypeIdFilter.value = search.value.sport_type_id;
    };

    return {
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSportTeamListTable,

      sportTeamLists,
      refetchData,
      nameFilter,
      search,

      resetFilter,
      sportTypeIdFilter,

      resolveStatus,
      statusOptions,
      numberFormat,
      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
