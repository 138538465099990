var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-edit-agency-history","ok-variant":"primary","cancel-variant":"outline-secondary","cancel-title":_vm.$t('Close'),"centered":"","no-enforce-focus":"","modal-class":"modal-primary","title":_vm.$t('Detail Agency History')},on:{"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"updatePostModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"amount"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t("Amount ")))]),_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"disabled":""},model:{value:(_vm.post.amount),callback:function ($$v) {_vm.$set(_vm.post, "amount", $$v)},expression:"post.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"note"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t("Note ")))]),_c('validation-provider',{attrs:{"name":"note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"internal-payment-note","placeholder":"Note","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"trim":"","disabled":""},model:{value:(_vm.post.note),callback:function ($$v) {_vm.$set(_vm.post, "note", $$v)},expression:"post.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"month"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t("Month")))]),_c('validation-provider',{attrs:{"name":"month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataMonth,"reduce":function (val) { return val.value; },"placeholder":"Select month","input-id":"month","disabled":""},model:{value:(_vm.post.month),callback:function ($$v) {_vm.$set(_vm.post, "month", $$v)},expression:"post.month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"year"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t("Year")))]),_c('validation-provider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.years,"reduce":function (val) { return val.value; },"placeholder":"Select year","input-id":"year","disabled":""},model:{value:(_vm.post.year),callback:function ($$v) {_vm.$set(_vm.post, "year", $$v)},expression:"post.year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"logo"}},[_c('label',{attrs:{"for":""}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Bills"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])])]),_c('b-form-group',[_c('b-img',{staticClass:"mr-2 rounded",staticStyle:{"width":"150px"},attrs:{"src":_vm.post.showImg,"fluid":""}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.post.logo),expression:"post.logo"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.post.logo = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }