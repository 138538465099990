<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group label="Product" label-for="name" class="mb-0">
              <v-select
                  v-model="search.sport_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="codeProduct"
                  :searchable="false"
                  class="w-100"
                  :reduce="(val) => val.value"
              />

              <div style="margin: 8px 0px; color: red" v-if="isProductId">
                The field is require
              </div>
              <!-- :clearable="false" -->
              <!-- input-id="category" -->
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                  id="fromDate"
                  v-model="search.fromdate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isFromDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                  id="toDate"
                  v-model="search.todate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isToDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-end justify-content-end">
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
          ref="refSportTeamListTable"
          class="position-relative table-white-space mb-0 max-height-table"
          sticky-header
          head-variant="light"
          :items="dataAccountWinLoss"
          responsive
          :fields="tableColumns"
          primary-key="user_id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(bet)="data">
          {{ numberFormat(data.item.bet / 1000) }}
        </template>

        <template #cell(win)="data">
          {{ numberFormat(data.item.win / 1000) }}
        </template>

        <template #cell(lost)="data">
          {{ numberFormat(data.item.lost / 1000) }}
        </template>

        <template #cell(cancel)="data">
          {{ numberFormat(data.item.cancel / 1000) }}
        </template>

        <template #cell(tie)="data">
          {{ numberFormat(data.item.tie / 1000) }}
        </template>

        <template #cell(winLoss)="data">
          <span v-if="data.item.winLoss > 0" class="text-primary">
            {{ numberFormat(data.item.winLoss) }}
          </span>
          <span v-if="data.item.winLoss <= 0" class="text-danger">
            {{ numberFormat(data.item.winLoss) }}
          </span>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
            >Showing {{ dataMeta.of }} entries</span
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
import {formatDateTime, numberFormat} from "@core/utils/filter";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import flatPickr from "vue-flatpickr-component";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import sportsStoreModule from "@/views/sports/sportsStoreModule";

export default {
  components: {
    flatPickr,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {},
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    if (!store.hasModule("sports"))
      store.registerModule("sports", sportsStoreModule);
    onUnmounted(() => {
      // if (store.hasModule("marketing")) store.unregisterModule("marketing");
      if (store.hasModule("sports")) store.unregisterModule("sports");
    });

    const toast = useToast();

    const search = ref({
      username: "",
      sport_type_id: "",
      fromdate: "",
      todate: "",
    });
    const resolveTypeStatus = (status) => {
      if (status === 1) return {label: "place bet", variant: "success"};
      if (status === 2) return {label: "win", variant: "secondary"};
      if (status === 3) return {label: "loss", variant: "Primary"};
      if (status === 4) return {label: "cancel", variant: "Warning"};
      if (status === 5) return {label: "tie", variant: "Danger"};
      return {label: "None", variant: "secondary"};
    };
    // Status: 1:place bet, 2: win 3: loss, 4:cancel, 5: tie (lưu í nếu status nào k có thì hiển thị trống)
    const usernameFilter = ref(null);
    const sportTypeId = ref([]);
    const sportTypeIdFilter = ref(null);
    const toDateFilter = ref(null);
    const fromDateFilter = ref(null);
    const codeProduct = ref([]);
    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref();
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isProductId = ref(false);

    const refSportTeamListTable = ref(null);
    const isFromDate = ref(false);
    const isToDate = ref(false);
    const tableColumns = [
      {key: "index", label: "#", sortable: false},
      {key: "username", label: "Username", sortable: true},
      {key: "bet", label: "Bet", sortable: true},
      {key: "win", label: "Win", sortable: true},
      {key: "lost", label: "Lost", sortable: true},
      {key: "cancel", label: "Cancel", sortable: true},
      {key: "tie", label: "Tie", sortable: true},
      {key: "winLoss", label: "Win/Loss", sortable: true},
    ];

    const refetchData = () => {
      refSportTeamListTable.value.refresh();
    };

    watch(
        [currentPage, toDateFilter, sportTypeIdFilter, fromDateFilter],
        () => {
          refetchData();
        }
    );
    const dataVendorWinLoss = ref();
    const transformedArray = ref();
    const sportTeamLists = (ctx, callback) => {
      store
          .dispatch("marketing/fetchAgencyWinLossByUser", {
            agencyCode: props.userData?.code,
            productId: sportTypeIdFilter.value,
            fromDate: fromDateFilter.value,
            toDate: toDateFilter.value
          })
          .then((response) => {
            let result = response.data.data;
            perPage.value = response.pageSize;
            dataVendorWinLoss.value = Object.values(result).map((item, index) => {
              let winLoss = item.win/1000 + item.cancel/1000 + item.tie/1000 - item.bet/1000;
              return {...item, winLoss: winLoss, index: index + 1}
            });
            totalSportTeam.value = dataVendorWinLoss.value.length;
          });
    };
    // sportTeamLists();
    store.dispatch("sports/fetchBonusCodeProduct", {}).then((response) => {
      codeProduct.value = response.data.data.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });

    const dataMeta = computed(() => {
      return {
        of: totalSportTeam.value,
      };
    });

    const resetFilter = () => {
      // search.value.name = "";
      search.value.sport_type_id = "";
      search.value.fromdate = "";
      search.value.todate = "";
      toDateFilter.value = null;
      fromDateFilter.value = null;
      sportTypeIdFilter.value = null;
      sportTeamLists();
    };

    const searchFilter = () => {
      isFromDate.value = false;
      isToDate.value = false;
      isProductId.value = false;
      if (search.value.sport_type_id === "" || !search.value.sport_type_id) {
        isProductId.value = true;
      }
      if (search.value.fromdate === "" || !search.value.fromdate) {
        isFromDate.value = true;
      }
      if (search.value.todate === "" || !search.value.todate) {
        isToDate.value = true;
      }

      if (search.value.sport_type_id &&
          search.value.fromdate !== "" &&
          search.value.todate !== "" &&
          search.value.sport_type_id !== ""
      ) {
        isFromDate.value = false;
        isToDate.value = false;
        isProductId.value = false;
        sportTypeIdFilter.value = search.value.sport_type_id;
        toDateFilter.value = search.value.todate;
        fromDateFilter.value = search.value.fromdate;
        usernameFilter.value = search.value.username;
        sportTeamLists();
      }
    };

    return {
      isProductId,
      codeProduct,
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refSportTeamListTable,
      sportTeamLists,
      refetchData,
      toDateFilter,
      search,
      resetFilter,
      sportTypeIdFilter,
      numberFormat,
      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
      fromDateFilter,
      isFromDate,
      isToDate,
      dataAccountWinLoss: dataVendorWinLoss,
      resolveTypeStatus,
      transformedArray,
      usernameFilter
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
