<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                  id="fromDate"
                  v-model="search.fromdate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isFromDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                  id="toDate"
                  v-model="search.todate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isToDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="3" sm="6">
            <b-form-group label=" " class="mb-2" style="margin-top: 15px">
              <b-button variant="primary" @click="searchFilter()">
                Search
              </b-button>
              <b-button variant="outline-primary" class="ml-2" @click="resetFilter()">
                Reset
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
          ref="refWinLossAgencyListTable"
          class="position-relative table-white-space mb-0 max-height-table"
          sticky-header
          head-variant="light"
          :items="dataAccountWinLoss"
          responsive
          :fields="tableColumns"
          primary-key="index"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(bet)="data">
          {{ numberFormat(data.item.bet / 1000) }}
        </template>

        <template #cell(win)="data">
          {{ numberFormat(data.item.win / 1000) }}
        </template>

        <template #cell(lost)="data">
          {{ numberFormat(data.item.lost / 1000) }}
        </template>

        <template #cell(cancel)="data">
          {{ numberFormat(data.item.cancel / 1000) }}
        </template>

        <template #cell(tie)="data">
          {{ numberFormat(data.item.tie / 1000) }}
        </template>

        <template #cell(winLoss)="data">
          <span v-if="data.item.winLoss > 0" class="text-primary">
            {{ numberFormat(data.item.winLoss) }}
          </span>
          <span v-if="data.item.winLoss <= 0" class="text-danger">
            {{ numberFormat(data.item.winLoss) }}
          </span>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
            >Showing {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalSportTeam"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
import {formatDateTime, numberFormat} from "@core/utils/filter";
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import sportsStoreModule from "@/views/sports/sportsStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";

export default {
  components: {
    flatPickr,
    // AddsportTeamModal,
    // EditsportTeamModal,
    AddSportsTeamModal,
    UpdateSportTeamModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {},
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    // onUnmounted(() => {
    //   if (store.hasModule("marketing")) store.unregisterModule("marketing");
    // });

    const sportTypeId = ref([]);
    const codeProduct = ref([]);
    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isProductId = ref(false);

    const refWinLossAgencyListTable = ref(null);
    const isFromDate = ref(false);
    const isToDate = ref(false);
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 30);
    const fromDateFilter = ref(sevenDaysAgo);
    const toDateFilter = ref(currentDate);

    let search = ref({
      fromdate: sevenDaysAgo,
      todate: currentDate,
    });

    const tableColumns = [
      {key: "index", label: "#", sortable: false},
      {key: "name", label: "Product Name", sortable: true},
      {key: "bet", label: "Bet", sortable: true},
      {key: "win", label: "Win", sortable: true},
      {key: "lost", label: "Lost", sortable: true},
      {key: "cancel", label: "Cancel", sortable: true},
      {key: "tie", label: "Tie", sortable: true},
      {key: "winLoss", label: "Win/Loss", sortable: true},
    ];

    const refetchData = () => {
      refWinLossAgencyListTable.value.refresh();
    };

    watch(
        [currentPage],
        () => {
          refetchData();
        }
    );
    const dataAccountWinLoss = ref([]);
    const sportTeamLists = (ctx, callback) => {
      store
          .dispatch("marketing/fetchAgencyWinLoss", {
            agencyId: props.userData?.id,
            fromDate: moment(fromDateFilter.value).format('YYYY-MM-DD'),
            toDate: moment(toDateFilter.value).format('YYYY-MM-DD'),
            page: currentPage.value,
          })
          .then((response) => {
            // callback(response.sportTeam);
            let result = response.sportTeam;
            totalSportTeam.value = response.total;
            perPage.value = currentPage.value === 1 ? response.pageSize : 25;
            dataAccountWinLoss.value = Object.values(result).map((item, index) => {
              let winLoss = item.win / 1000 + item.cancel / 1000 + item.tie / 1000 - item.bet / 1000;
              return {...item, winLoss: winLoss, index: index + 1}
            });
          });
    };
    // sportTeamLists();
    store
        .dispatch("marketing/fetchAgencyWinLoss", {
          agencyId: props.userData?.id,
          fromDate: moment(fromDateFilter.value).format('YYYY-MM-DD'),
          toDate: moment(toDateFilter.value).format('YYYY-MM-DD'),
          page: currentPage.value,
        })
        .then((response) => {
          // callback(response.sportTeam);
          let result = response.sportTeam;
          totalSportTeam.value = response.total;
          perPage.value = currentPage.value === 1 ? response.pageSize : 25;
          dataAccountWinLoss.value = Object.values(result).map((item, index) => {
            let winLoss = item.win / 1000 + item.cancel / 1000 + item.tie / 1000 - item.bet / 1000;
            return {...item, winLoss: winLoss, index: index + 1}
          });
        });

    const dataMeta = computed(() => {
      return {
        of: dataAccountWinLoss.value.length,
      };
    });

    const resetFilter = () => {
      search.value.fromdate = "";
      search.value.todate = "";
    };

    const searchFilter = () => {
      if (search.value.fromdate === "") {
        isFromDate.value = true;
      }
      if (search.value.todate === "") {
        isToDate.value = true;
      }

      if (
          search.value.fromdate !== "" &&
          search.value.todate !== ""
      ) {
        isFromDate.value = false;
        isToDate.value = false;
        fromDateFilter.value = search.value.fromdate;
        toDateFilter.value = search.value.todate;
        sportTeamLists();
      }
    };

    return {
      isProductId,
      codeProduct,
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refWinLossAgencyListTable,
      sportTeamLists,
      refetchData,
      search,
      resetFilter,
      numberFormat,
      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
      isFromDate,
      isToDate,
      dataAccountWinLoss,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
