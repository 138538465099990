<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Month" label-for="month" class="mb-2">
              <v-select v-model="search.month" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="monthOption"
                :clearable="false" placeholder="Select month" label="Month" input-id="month" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Year" label-for="year" class="mb-2">
              <v-select v-model="search.year" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="yearOption"
                :clearable="false" placeholder="Select year" label="Year" input-id="year" />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label=" " class="mb-2" style="margin-top: 15px">
              <b-button variant="primary" @click="searchFilter()">
                Search
              </b-button>
              <b-button variant="outline-primary" class="ml-2" @click="resetFilter()">
                Reset
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table ref="refWinLossAgencyListTable" class="position-relative table-white-space mb-0 max-height-table"
        sticky-header head-variant="light" :items="commissionResult" responsive :fields="tableColumns" primary-key="index"
        :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
        <template #cell(discount)="data">
          {{ data.item.discount ? numberFormat(data.item.discount) : "" }}
        </template>
        <template #cell(total_amount_bonus)="data">
          {{ data.item.total_amount_bonus ? numberFormat(data.item.total_amount_bonus / 1000) : "" }}
        </template>
        <template #cell(total_amount_deposit)="data">
          {{ data.item.total_amount_deposit ? numberFormat(data.item.total_amount_deposit / 1000) : "" }}
        </template>
        <template #cell(total_amount_withdrawal)="data">
          {{ data.item.total_amount_withdrawal ? numberFormat(data.item.total_amount_withdrawal / 1000) : "" }}
        </template>
        <template #cell(total_bet)="data">
          {{ data.item.total_bet ? numberFormat(data.item.total_bet / 1000) : "" }}
        </template>
        <template #cell(total_cancel)="data">
          {{ data.item.total_cancel ? numberFormat(data.item.total_cancel / 1000) : "" }}
        </template>
        <template #cell(total_tie)="data">
          {{ data.item.total_tie ? numberFormat(data.item.total_tie / 1000) : "" }}
        </template>
        <template #cell(total_win)="data">
          {{ data.item.total_win ? numberFormat(data.item.total_win / 1000) : "" }}
        </template>
        <template #cell(bonus_first_transfer)="data">
          {{ data.item.bonus_first_transfer ? numberFormat(data.item.bonus_first_transfer / 1000) : "" }}
        </template>
        <template #cell(debt_previous_month)="data">
          {{ data.item.debt_previous_month ? numberFormat(data.item.debt_previous_month / 1000) : "" }}
        </template>
        <template #cell(total_win_loss)="data">
          <span v-if="data.item.total_win_loss > 0" class="text-primary">
            {{ numberFormat(data.item.total_win_loss) }}
          </span>
          <span v-if="data.item.total_win_loss <= 0" class="text-danger">
            {{ numberFormat(data.item.total_win_loss) }}
          </span>
        </template>
        <template #cell(fee)="data">
          {{ data.item.fee ? numberFormat(data.item.fee) : "" }}
        </template>
        <template #cell(net_amount_commision)="data">
          <span v-if="data.item.net_amount_commision > 0" class="text-primary">
            {{ numberFormat(data.item.net_amount_commision) }}
          </span>
          <span v-if="data.item.net_amount_commision <= 0" class="text-danger">
            {{ numberFormat(data.item.net_amount_commision) }}
          </span>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalSportTeam" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";
import i18n from "@/libs/i18n";

export default {
  components: {
    flatPickr,
    AddSportsTeamModal,
    UpdateSportTeamModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {},
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    // onUnmounted(() => {
    //   if (store.hasModule("marketing")) store.unregisterModule("marketing");
    // });

    const getListYear = () => {
      const currentYear = new Date().getFullYear();
      const startYear = 2023;
      const yearList = [];
      for (let i = startYear; i <= currentYear; i++) {
        yearList.push(i);
      }
      return yearList;
    };

    const search = ref({
      month: "",
      year: "",
    });
    const resolveTypeStatus = (status) => {
      if (status === 1) return { label: "place bet", variant: "success" };
      if (status === 2) return { label: "win", variant: "secondary" };
      if (status === 3) return { label: "loss", variant: "Primary" };
      if (status === 4) return { label: "cancel", variant: "Warning" };
      if (status === 5) return { label: "tie", variant: "Danger" };
      return { label: "None", variant: "secondary" };
    };
    // Status: 1:place bet, 2: win 3: loss, 4:cancel, 5: tie (lưu í nếu status nào k có thì hiển thị trống)
    const monthOption = ref(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const yearOption = ref(getListYear());
    const sportTypeIdFilter = ref(null);
    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isProductId = ref(false);

    const refWinLossAgencyListTable = ref(null);
    const isMonth = ref(false);
    const isYear = ref(false);
    const tableColumns = [
      { key: "year", label: i18n.t("Year"), sortable: true },
      { key: "month", label: i18n.t("Month"), sortable: true },
      { key: "discount", label: i18n.t("Hoa hồng (%)"), sortable: true },
      { key: "total_amount_deposit", label: i18n.t("Total amount deposit"), sortable: true },
      { key: "total_amount_withdrawal", label: i18n.t("Total amount withdrawal"), sortable: true },
      { key: "total_bet", label: i18n.t("Total bet"), sortable: true },
      { key: "total_cancel", label: i18n.t("Total cancel"), sortable: true },
      { key: "total_quantity_deposit", label: i18n.t("Total quantity deposit"), sortable: true },
      { key: "total_quantity_withdrawal", label: i18n.t("Total quantity withdrawal"), sortable: true },
      { key: "total_tie", label: i18n.t("Total tie"), sortable: true },
      { key: "total_win", label: i18n.t("Total win"), sortable: true },
      { key: "total_win_loss", label: i18n.t("Total win loss"), sortable: true },
      { key: "total_amount_bonus", label: i18n.t("Total amount bonus"), sortable: true },
      { key: "fee", label: i18n.t("Fee"), sortable: true },
      { key: "net_amount_commision", label: i18n.t("net Amount Commision"), sortable: true },
      { key: "bonus_first_transfer", label: i18n.t("Bonus First Transfer"), sortable: true },
      { key: "debt_previous_month", label: i18n.t("Debt Previous Month"), sortable: true },
      { key: "upd", label: i18n.t("UPD"), sortable: false },
      { key: "upb", label: i18n.t("UPB"), sortable: false },
      { key: "new_uap", label: i18n.t("UAP New"), sortable: false },
      { key: "new_user_register", label: i18n.t("New User"), sortable: false },
      { key: "note", label: i18n.t("note"), sortable: false }
    ];

    const refetchData = () => {
      refWinLossAgencyListTable.value.refresh();
    };

    watch(
      [currentPage, sportTypeIdFilter],
      () => {
        refetchData();
      }
    );
    const commissionResult = ref([]);
    const sportTeamLists = (ctx, callback) => {
      store
        .dispatch("marketing/fetchAgencyCommission", {
          agencyCode: props.userData?.code,
          month: search.value.month,
          year: search.value.year,
          page: currentPage.value,
        })
        .then((response) => {
          commissionResult.value = response.result;
        });
    };

    store
      .dispatch("marketing/fetchAgencyCommission", {
        agencyCode: props.userData?.code,
        page: currentPage.value,
      })
      .then((response) => {
        commissionResult.value = response.result;
      });

    const dataMeta = computed(() => {
      const localItemsCount = refWinLossAgencyListTable.value
        ? refWinLossAgencyListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: commissionResult.value.length,
      };
    });

    const resetFilter = () => {
      search.value.month = "";
      search.value.year = "";
      sportTeamLists();
    };

    const searchFilter = () => {
      sportTeamLists();
    };

    return {
      isProductId,
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refWinLossAgencyListTable,
      sportTeamLists,
      refetchData,
      search,
      resetFilter,
      sportTypeIdFilter,
      numberFormat,
      formatDateTime,
      searchFilter,
      baseUrl,
      monthOption,
      yearOption,
      isMonth,
      isYear,
      resolveTypeStatus,
      commissionResult,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
