<template>
  <div>
    <b-card>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Name" label-for="name" class="mb-2">
              <b-form-input
                id="name"
                v-model="search.name"
                class="form-control"
                placeholder="Please Enter Name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Email" label-for="email" class="mb-2">
              <b-form-input
                id="email"
                v-model="search.email"
                class="form-control"
                placeholder="Please Enter Email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Phone" label-for="phone" class="mb-2">
              <b-form-input
                id="phone"
                v-model="search.phone"
                class="form-control"
                placeholder="Please Enter phone"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12" md="3">
            <b-form-group label="Discount" label-for="discount" class="mb-2">
              <b-form-input
                id="discount"
                v-model="search.discount"
                class="form-control"
                placeholder="Please Enter discount"
              />
            </b-form-group>
          </b-col> -->

          <b-col cols="12" md="3">
            <b-form-group label="Status" label-for="name" class="mb-2">
              <v-select
                v-model="search.sport_type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sportTypeId"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="Select status"
                label="name"
                input-id="category"
              />
            </b-form-group>
          </b-col>
          <!-- from Date Filter -->
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                id="fromDate"
                v-model="search.fromDate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                id="toDate"
                v-model="search.toDate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- md="4" -->
          <b-col cols="12" class="d-flex align-items-end justify-content-end">
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <!-- <b-col cols="12" v-if="$can('create', 'sportteam')">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showAddSportTeamModal()">
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> New
                </span>
              </b-button>
            </div>
          </b-col> -->
        </b-row>
      </div>
      <b-table
        ref="refUserAgencyListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :items="userAgencyLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(user)="data">
          <b-link class="cursor-pointer text-primary">
            {{ data.item.username }}
          </b-link>
        </template>
        <template #cell(status)="data">
          <b-link class="cursor-pointer text-primary">
            <div :class="` text-${resolveUserStatus(data.item.stat).variant}`">
              {{ resolveUserStatus(data.item.stat).label }}
            </div>
          </b-link>
        </template>
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>
        <template #cell(phone)="data">
          {{ data.item.phone }}
        </template>
        <template #cell(level)="data">
          {{ findNameById(data.item.level_id) }}
        </template>
        <template #cell(txn_dtos_count)="data">
          {{ data.item.txn_dtos_count }}
        </template>
        <template #cell(txn_dtos_sum_amount)="data">
          <div>
            {{
              data.item.txn_dtos_sum_amount
                ? numberFormat(data.item.txn_dtos_sum_amount / 1000)
                : 0
            }}
          </div>
          <!-- <div v-else>0</div> -->
        </template>
        <template #cell(createdAt)="data">
          <div v-if="data.item.created_at !== null">
            {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
          </div>
          <div v-else></div>
        </template>

        <!-- <template #cell(action)="data">
          <b-link
            v-if="$can('update', 'sportteam')"
            class="font-weight-bold text-nowrap"
            @click="showEditSportTeamModal(data.item)"
          >
            Edit
          </b-link>
        </template> -->
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSportTeam"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal: Add sports Group -->
    <!-- <add-sports-team-modal
      :sport-type-id.sync="sportTypeId"
      @refetch-data="refetchData"
    /> -->

    <!-- Modal: Edit sports Group -->
    <!-- <update-sport-team-modal
      :sport-type-id.sync="sportTypeId"
      :sport-team-detail.sync="sportTeamDetail"
      @refetch-data="refetchData"
    /> -->
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
// import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
// import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import { resolveUserStatus } from "@core/utils/const/user";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    // AddsportTeamModal,
    // EditsportTeamModal,
    // AddSportsTeamModal,
    // UpdateSportTeamModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {},
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    // onUnmounted(() => {
    //   if (store.hasModule("marketing")) store.unregisterModule("marketing");
    // });
    const propsId = ref(props.id);
    const toast = useToast();

    const search = ref({
      name: "",
      email: "",
      phone: "",
      // discount: "",
      toDate: "",
      fromDate: "",
      sport_type_id: "",
    });
    const sportTypeId = [
      { name: "activce", id: 1 },
      { name: "lock", id: 2 },
      { name: "inactive", id: 3 },
    ];
    const sportTypeIdFilter = ref(null);
    const userNameFilter = ref(null);
    const emailFilter = ref(null);
    const phoneFilter = ref(null);
    // const discountFilter = ref(null);
    const fromDateFilter = ref(null);
    const toDateFilter = ref(null);
    const agency_id = ref(props.userData.id);

    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const refUserAgencyListTable = ref(null);

    const tableColumns = [
      { key: "index", label: "#" },
      { key: "user", label: "Tên đăng nhập", stickyColumn: true },
      { key: "status", label: "Status" },
      { key: "name", label: "Họ tên" },
      // { key: 'currency', label: i18n.t('Currency'), class: 'text-center' },
      { key: "email", label: "Email" },
      { key: "phone", label: "Phone" },
      { key: "level", label: "Level" },
      { key: "txn_dtos_count", label: "Tổng số lệnh nạp" },
      { key: "txn_dtos_sum_amount", label: "Tổng số tiền nạp" },
      { key: "createdAt", label: "Created at" },
    ];

    const refetchData = () => {
      refUserAgencyListTable.value.refresh();
    };

    watch(
      [
        currentPage,
      ],
      () => {
        refetchData();
      }
    );

    const userAgencyLists = (ctx, callback) => {
      store
        .dispatch("marketing/fetchListUserAgency", {
          agency_id: agency_id.value,
          page: currentPage.value,
          username: userNameFilter.value,
          email: emailFilter.value,
          phone: phoneFilter.value,
          status: sportTypeIdFilter.value,
          // discount: discountFilter.value,
          fromdate: fromDateFilter.value,
          todate: toDateFilter.value,
        })
        .then((response) => {
          //   const { sportTeams, total, pageSize } = response;
          callback(response.data.data);
          totalSportTeam.value = response.data.count;
          perPage.value =
            currentPage.value == 1 ? response.data.data.length : 25;
        });
    };
    // userAgencyLists();
    const userLevelName = ref();
    store.dispatch("marketing/fetchLevel").then((response) => {
      userLevelName.value = response.data.data;
    });

    const findNameById = (id) => {
      const item = userLevelName.value.find((item) => item.id === id);
      if (item) {
        return item.name;
      }
    };
    const dataMeta = computed(() => {
      const localItemsCount = refUserAgencyListTable.value
        ? refUserAgencyListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportTeam.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "inActive", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };

    const resetFilter = () => {
      search.value.name = "";
      search.value.email = "";
      search.value.phone = "";
      // search.value.discount = "";
      search.value.fromDate = "";
      search.value.toDate = "";
      search.value.sport_type_id = "";
      userNameFilter.value = null;
      emailFilter.value = null;
      phoneFilter.value = null;
      // discountFilter.value = null;
      fromDateFilter.value = null;
      toDateFilter.value = null;
      sportTypeIdFilter.value = null;
    };

    const searchFilter = () => {
      userNameFilter.value = search.value.name;
      emailFilter.value = search.value.email;
      phoneFilter.value = search.value.phone;
      // discountFilter.value = search.value.discount;
      fromDateFilter.value = search.value.fromDate;
      toDateFilter.value = search.value.toDate;
      sportTypeIdFilter.value = search.value.sport_type_id;
      refetchData();
    };

    return {
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserAgencyListTable,

      userAgencyLists,
      findNameById,
      // nameFilter,
      search,

      resetFilter,
      sportTypeIdFilter,
      emailFilter,
      phoneFilter,
      // discountFilter,
      fromDateFilter,
      toDateFilter,
      // sport_type_id,

      resolveStatus,
      statusOptions,

      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
      resolveUserStatus,
      userLevelName,
      numberFormat,
      userNameFilter,
    };
  },
};
</script>
